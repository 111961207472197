<template>
  <div v-if="role">
    <b-card>
      <b-row>
        <b-col md="6">
          <b-form-group
              label="Libelle"
              label-for="libelle"

          >
            <b-form-input

                v-model="role.libelle"
                id="libelle"
                placeholder="Libelle"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Home page"
              label-for="home_page"

          >
            <b-form-input

                v-model="role.home_page"
                id="home_page"
                placeholder="Page d'acceuil"
            />

          </b-form-group>
        </b-col>


      </b-row>
    </b-card>
    <b-card title="Dashboard">
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="consulter-dashboard" class="pr-1">
          Accès au dashboard
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="consulter-dashboard-global" class="pr-1">
          Dashboard Niveau Global
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="consulter-dashboard-concession" class="pr-1">
          Dashboard Niveau Concession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="consulter-dashboard-site" class="pr-1">
          Dashboard Niveau Site
        </b-form-checkbox>
      </div>
    </b-card>
    <b-card title="Catalogue">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-catalogue" class="pr-1">
          Consultation
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="exporter-catalogue" class="pr-1">
          Exporter
        </b-form-checkbox>
      </div>


      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-catalog-global" class="pr-1">
          Listing Niveau Global
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-catalog-concession" class="pr-1">
          Listing Niveau Concession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-catalog-site" class="pr-1">
          Listing Niveau Site
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-catalog-utilisateur" class="pr-1">
          Listing Niveau Utilisateur
        </b-form-checkbox>
      </div>
    </b-card>
    <b-card title="Reprise">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-reprise" class="pr-1">
          Consultation
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="exporter-reprise" class="pr-1">
          Exporter
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="creer-modifier-reprise" class="pr-1">
          Créer modifier reprise
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="gestion-expertise-manuelle" class="pr-1">
          Gestion Expertise Manuelle
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="gestion-expertise-dekra" class="pr-1">
          Gestion Expertise Dekra
        </b-form-checkbox>
        
        <b-form-checkbox v-model="role.permissions" value="coter-vehicule" class="pr-1">
          Coter Véhicule
        </b-form-checkbox>

        <b-form-checkbox v-model="role.permissions" value="edition-statut-vente" class="pr-1">
          Edition Statut de vente
        </b-form-checkbox>

 
        
      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-reprise-global" class="pr-1">
          Listing Niveau Global
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-reprise-concession" class="pr-1">
          Listing Niveau Concession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-reprise-site" class="pr-1">
          Listing Niveau Site
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-reprise-utilisateur" class="pr-1">
          Listing Niveau Utilisateur
        </b-form-checkbox>
      </div>
    </b-card>

    <b-card title="Gestion de stock">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-gestion-stock" class="pr-1">
          Consultation
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="exporter-gestion-stock" class="pr-1">
          Exporter
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="creer-modifier-stock" class="pr-1">
          Créer Modifier Stock
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="gerer-changer-vendeur" class="pr-1">
          Modifier champ vendeur
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="supprimer-stock" class="pr-1">
          Supprimer Stock
        </b-form-checkbox>
      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-stock-global" class="pr-1">
          Listing Niveau Global
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-stock-concession" class="pr-1">
          Listing Niveau Concession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-stock-site" class="pr-1">
          Listing Niveau Site
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-stock-utilisateur" class="pr-1">
          Listing Niveau Utilisateur
        </b-form-checkbox>
      </div>
    </b-card>

    <b-card title="Vendu">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-vendu" class="pr-1">
          Consultation
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="exporter-vendu" class="pr-1">
          Exporter
        </b-form-checkbox>
      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-vendu-global" class="pr-1">
          Listing Niveau Global
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-vendu-concession" class="pr-1">
          Listing Niveau Concession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-vendu-site" class="pr-1">
          Listing Niveau Site
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-vendu-utilisateur" class="pr-1">
          Listing Niveau Utilisateur
        </b-form-checkbox>
      </div>
    </b-card>


    <b-card title="Marchand">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-marchand" class="pr-1">
          Consultation
        </b-form-checkbox>

      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-marchand-global" class="pr-1">
          Listing Niveau Global
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-marchand-concession" class="pr-1">
          Listing Niveau Concession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-marchand-site" class="pr-1">
          Listing Niveau Site
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-marchand-utilisateur" class="pr-1">
          Listing Niveau Utilisateur
        </b-form-checkbox>
      </div>
    </b-card>

    <b-card title="List des Offres">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-liste-offres" class="pr-1">
          Consultation
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="exporter-liste-offres" class="pr-1">
          Exporter
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="creer-offre-reprise" class="pr-1">
          Créer Offre Reprise
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="creer-offre-vente" class="pr-1">
          Créer Offre Vente
        </b-form-checkbox>
      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-offres-global" class="pr-1">
          Listing Niveau Global (X)
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-offres-concession" class="pr-1">
          Listing Niveau Concession (X)
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-offres-site" class="pr-1">
          Listing Niveau Site (X)
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-offres-utilisateur" class="pr-1">
          Listing Niveau Utilisateur (X)
        </b-form-checkbox>
      </div>
    </b-card>


    <b-card title="Client">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-client" class="pr-1">
          Consultation
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="exporter-client" class="pr-1">
          Exporter
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="creer-client" class="pr-1">
          Créer Client
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="supprimer-client" class="pr-1">
          Supprimer Client (?)
        </b-form-checkbox>
      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-client-global" class="pr-1">
          Listing Niveau Global (X)
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-client-concession" class="pr-1">
          Listing Niveau Concession (X)
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-client-site" class="pr-1">
          Listing Niveau Site (X)
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-client-utilisateur" class="pr-1">
          Listing Niveau Utilisateur (X)
        </b-form-checkbox>
      </div>
    </b-card>


    <b-card title="Livraison">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="consulter-livraison" class="pr-1">
          Consultation
        </b-form-checkbox>

      </div>
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-model="role.permissions" value="voir-livraison-global" class="pr-1">
          Listing Niveau Global
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-livraison-concession" class="pr-1">
          Listing Niveau Concession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-livraison-site" class="pr-1">
          Listing Niveau Site
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="voir-livraison-utilisateur" class="pr-1">
          Listing Niveau Utilisateur
        </b-form-checkbox>
      </div>
    </b-card>

    <b-card title="Site">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="manage-site" class="pr-1">
          Gérer Site
        </b-form-checkbox>

      </div>

    </b-card>
    <b-card title="Utilisateur">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="manage-utilisateur" class="pr-1">
          Gérer Utilisateur
        </b-form-checkbox>

      </div>

    </b-card>
    <b-card title="Concession">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="manage-concession" class="pr-1">
          Gérer Connession
        </b-form-checkbox>
        <b-form-checkbox v-model="role.permissions" value="switcher-concession" class="pr-1">
          Switcher entre Site/Connession
        </b-form-checkbox>
      </div>
    </b-card>
    <b-card title="Role">
      <div class="d-flex flex-wrap justify-content-start align-items-center mb-2">
        <b-form-checkbox v-model="role.permissions" value="gestion-role" class="pr-1">
          Gérer Role
        </b-form-checkbox>
      </div>
    </b-card>
    <b-card v-if="false" v-for="(item, index) in permissions" :key="item" :title="index">
      <div class="d-flex flex-wrap justify-content-start align-items-center">
        <b-form-checkbox v-for="resource in item" :key="resource.id" v-model="role.permissions" :value="resource.id"
                         class="pr-1">
          {{ resource.libelle }}
        </b-form-checkbox>
      </div>
    </b-card>
    <div class="text-right">
      <b-button :disabled="pending" @click="saveHandler" variant="primary">Enregistrer</b-button>
    </div>
  </div>
</template>

<script>
import axios from "@/libs/axios";

export default {
  name: "RoleViewPage",
  data() {
    return {
      role: {
        id: null,
        libelle: '',
        permissions: []
      },
      permissions: [],
      pending : false
    };
  },
  methods: {
    fetch() {
      let _this = this;
      
      if(this.$route.params.id == 'creer') return;
      axios.get('/api/roles/' + this.$route.params.id).then(function (response) {
        _this.role = response.data.data;
        _this.permissions = response.data.permissions;
      })
    },
    saveHandler() {
      let _this = this;
      _this.pending = true; 
      axios.post('/api/roles/store', {
        ...this.role
      }).then(function (response) {

        _this.$router.push('/roles');

      })
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>

<style scoped>

</style>